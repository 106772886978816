<template>
  <portfolio-page
      art-board-image="/img/cover-01.png"
      art-board-url="https://vikingcruises.com"
      page-title="Viking Cruises"
  >
    <template v-slot:info-content>
      <h3 class="text-balance text-3xl font-semibold tracking-tight text-gray-700 mb-14">
        Marketing Website
      </h3>

      <p class="text-xl text-gray-700 mb-14">
        During my time at Sourcetoad, one of my early and significant contributions was helping to develop a new
        marketing website for Viking Cruises. This project included cruise date filtering, booking systems, detailed
        informational pages, and integrations with Google Maps and Instagram APIs. It was my longest-running project
        and involved collaborating with a cross-functional team of programmers and designers from around the globe.
        The experience I gained was invaluable, equipping me with skills and insights that prepared me for future
        challenges.
      </p>

      <h3 class="text-balance text-3xl font-semibold tracking-tight text-gray-700 mb-14">
        Mobile App
      </h3>

      <p class="text-xl text-gray-700 mb-7">
        Another highly engaging project I contributed to for Viking Cruises was their mobile app, which provides
        essential information for pre-cruise, onboard, and land excursions. During the COVID-19 pandemic, we enhanced
        the app to include in-app and in-cabin health check-ins and exposure notifications, helping to keep passengers
        safe and minimize potential incidents.
      </p>

      <p class="text-xl text-gray-700 mb-14">
        This role also provided an excellent opportunity to explore and implement Detox end-to-end testing procedures,
        including video output and detailed success/failure reporting. While Detox was still evolving at the time,
        I’m eager to revisit it and experiment with its latest features in its current state.
      </p>

      <h3 class="text-balance text-3xl font-semibold tracking-tight text-gray-700 mb-14">
        Take-A-Ways
      </h3>

      <p class="text-xl text-gray-700 mb-14">
        Along the way, I picked up skills in Knockout.js, Sass (SCSS), design systems, React Native, Google Marketplace
        integration, and even a bit of C#. However, the most valuable experience was learning how to better accommodate
        web accessibility needs. Preparing websites for screen readers and alternative input devices was both
        eye-opening and incredibly rewarding.
      </p>
    </template>
    <template v-slot:level-up-content>
      <ul class="list-image-starG list-inside">
        <li class="leading-10">Web Accessibility</li>
        <li class="leading-10">Sass (Scss)</li>
        <li class="leading-10">React Native</li>
      </ul>
      <ul class="list-image-star list-inside">
        <li class="leading-10">Knockout.js</li>
        <li class="leading-10">Gulp.js</li>
        <li class="leading-10">Design Systems</li>
        <li class="leading-10">End-To-End Testing</li>
        <li class="leading-10">Google Maps API</li>
        <li class="leading-10">Google Marketplace</li>
      </ul>
      <ul class="list-image-starH list-inside">
        <li class="leading-10">Apple Store</li>
        <li class="leading-10">C#</li>
      </ul>
    </template>
  </portfolio-page>
</template>

<script>
import PortfolioPage from "@/components/PortfolioPage.vue";

export default {
  components: {
    PortfolioPage,
  }
}
</script>
