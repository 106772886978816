<template>
  <div class="sticky top-0 z-50">
    <div class="bg-gradient-to-r from-violet-500 to-fuchsia-500 pb-1">

        <div class="navbar bg-neutral text-neutral-content">
          <div class="max-w-6xl w-full m-auto">
            <img class="h-6 w-auto ml-3" src="/img/logo-sm.png" alt="" />

            <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('top')">
              Home
            </button>
            <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('about')">
              About
            </button>
            <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('portfolio')">
              Portfolio
            </button>
            <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('projects')">
              Projects
            </button>
            <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('contact')">
              Contact
            </button>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
import { scrollToSection } from '@/utils/utils';

export default {
  data() {
    return {
      scrollToSection,
    }
  }
};
</script>
