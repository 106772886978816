<template>
  <section id="portfolio" class="relative pt-24 pb-8 bg-gradient-to-r from-violet-500 to-fuchsia-500 overflow-hidden">
    <div class="relative isolate mb-14">
      <div aria-hidden="true" class="absolute inset-x-0 z-0 transform-gpu overflow-hidden blur-3xl lg:top-[-600px]">
        <div
            style="clip-path: ellipse(49% 31% at 50% 50%);"
            class="relative xl:left-[calc(50%-34rem)] top-16 aspect-[1155/678] md:w-auto xl:max-w-6xl bg-gradient-to-tr from-black to-fuchsia-500 opacity-40">
        </div>
      </div>

      <div class="relative max-w-6xl w-auto m-auto">
        <h2 class="text-balance text-5xl font-semibold tracking-tight text-gray-50 text-center mb-6">Portfolio</h2>

        <p class="text-center text-xl text-gray-50 max-w-3xl mx-auto">
          Due to confidentiality and the nature of these projects—some of which are accessible only on-site,
          like aboard a Viking Cruise ship, or within specific companies—I can’t provide extensive details. However,
          I’m pleased to share a curated selection of projects that I found particularly engaging and fulfilling.
        </p>

        <div class="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-12 xl:mx-0">
          <div v-for="project in portfolio" :key="project.id" class="relative bg-neutral shadow p-8 rounded-lg text-center overflow-hidden">
            <div aria-hidden="true" class="absolute inset-x-0 z-0 transform-gpu overflow-hidden blur-3xl">
              <div
                  style="clip-path: ellipse(49% 31% at 50% 50%);"
                  class="relative aspect-[1155/678] md:w-auto xl:max-w-6xl bg-gradient-to-tr from-white to-orange-500 opacity-10">
              </div>
            </div>

            <div class="relative">
              <img :src="project.image" alt="Project Image" class="rounded-lg shadow-lg mb-4 m-auto">
              <h3 class="text-xl font-semibold">{{ project.name }}</h3>
              <p class="text-gray-400">{{ project.description }}</p>
              <button class="btn btn-sm btn-outline btn-secondary mt-6" v-on:click="gotoPortfolio(project.url)">Read More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      portfolio: [
        {
          id: 1,
          name: "Viking Cruises",
          description: "Website & Mobile App",
          image: "/img/thumbs-viking.png",
          url: '/portfolio/viking-cruises'
        },
        {
          id: 2,
          name: 'Certegy (BankPay)',
          description: 'BankPack SDK for Android',
          image: '/img/thumbs-certegy.png',
          url: '/portfolio/certegy'
        },
        {
          id: 3,
          name: 'Thompson Consulting',
          description: 'Mobile App & ZPL Printers',
          image: '/img/thumbs-thompson.png',
          url: '/portfolio/thompson-consulting'
        },
        {
          id: 4,
          name: 'Northlink Ferries',
          description: 'VueJS & webOS',
          image: '/img/thumbs-northlink.png',
          url: '/portfolio/northlink-ferries'
        },
        {
          id: 5,
          name: 'Industry Genome',
          description: 'Laravel, VueJS & D3.js',
          image: '/img/thumbs-industry-genome.png',
          url: '/portfolio/industry-genome'
        },
        {
          id: 6,
          name: 'Graphic Designs',
          description: 'Photoshop & Illustrator',
          image: '/img/thumbs-wassaa.png',
          url: '/portfolio/graphic-design'
        },
      ],
    };
  },
  methods: {
    gotoPortfolio(url) {
      this.$router.push(url)
    }
  }
};
</script>
