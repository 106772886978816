<template>
  <portfolio-page
      art-board-image="/img/cover-industry-genome.png"
      art-board-url="https://industrygenome.com/industry-graph"
      page-title="Industry Genome"
  >
    <template v-slot:info-content>
      <h3 class="max-w-3xl mx-auto text-balance text-3xl font-semibold tracking-tight text-gray-700 mb-14">
        Website Build
      </h3>

      <p class="text-xl text-gray-700 max-w-3xl mx-auto mb-14">
        coming soon...
      </p>

      <h3 class="max-w-3xl mx-auto text-balance text-3xl font-semibold tracking-tight text-gray-700 mb-14">
        Vis.js & D3 Charts
      </h3>

      <p class="text-xl text-gray-700 max-w-3xl mx-auto mb-14">
        coming soon...
      </p>
    </template>
    <template v-slot:level-up-content>
      <ul class="list-image-starG list-inside">
        <li class="leading-10">Laravel</li>
        <li class="leading-10">Vue JS</li>
        <li class="leading-10">D3 Charts</li>
        <li class="leading-10">Vis Js Charts</li>
      </ul>
    </template>
  </portfolio-page>
</template>

<script>
import PortfolioPage from "@/components/PortfolioPage.vue";

export default {
  components: {
    PortfolioPage,
  }
}
</script>
