import { createRouter, createWebHistory} from 'vue-router'

import MainView from '../views/HomePage.vue';
import PortfolioViking from "@/views/PortfolioViking.vue";
import PortfolioCertegy from "@/views/PortfolioCertegy.vue";
import PortfolioThompson from "@/views/PortfolioThompson.vue";
import PortfolioNorthlink from "@/views/PortfolioNorthlink.vue";
import PortfolioIndustryGenome from "@/views/PortfolioIndustryGenome.vue";
import PortfolioGraphicDesign from "@/views/PortfolioGraphicDesign.vue";

const routes = [
    {
        path: '/',
        component: MainView
    },
    {
        path: '/portfolio/viking-cruises',
        component: PortfolioViking
    },
    {
        path: '/portfolio/certegy',
        component: PortfolioCertegy
    },
    {
        path: '/portfolio/thompson-consulting',
        component: PortfolioThompson
    },
    {
        path: '/portfolio/northlink-ferries',
        component: PortfolioNorthlink
    },
    {
        path: '/portfolio/industry-genome',
        component: PortfolioIndustryGenome
    },
    {
        path: '/portfolio/graphic-design',
        component: PortfolioGraphicDesign
    },
    {
        path: '/:catchAll(.*)',
        component: MainView
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
})

export default router