<template>
  <div class="text-center">
    <a :class="getStyle" v-on:click="goBack">Back</a>
  </div>
</template>

<script>

export default {
  props: {
    classNames: {
      type: String
    },
  },
  computed: {
    getStyle() {
      return this?.classNames || 'btn btn-outline btn-secondary text-xl';
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  }
}
</script>
