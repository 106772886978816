<template>
  <div class="bg-gradient-to-r from-violet-500 to-fuchsia-500 pt-0.5">
    <div class="relative bg-base-200 text-base-content pb-96 text-red">
      <svg
          aria-hidden="true"
          class="absolute h-full w-full"
          style="mask-image: radial-gradient(100% 100% at top right,white,transparent); stroke: rgb(255 255 255 / 0.1);"
      >
        <defs>
          <pattern x="50%" y="-1" id="squares-pattern" width="200" height="200" patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none"></path>
          </pattern>
        </defs>
        <rect fill="url(#squares-pattern)" width="100%" height="100%" stroke-width="0"></rect>
      </svg>

      <footer class="relative footer p-10 max-w-6xl w-full m-auto">
        <span class="sr-only">Phil Sanders</span>
        <img class="lgo max-w-16" src="/img/logo-sm.png" alt="Phil Sanders logo" />

        <nav>
          <h6 class="footer-title">Services</h6>
          <a class="link link-hover">Branding</a>
          <a class="link link-hover">Design</a>
        </nav>
        <nav>
          <h6 class="footer-title">Company</h6>
          <a class="link link-hover">About us</a>
          <a class="link link-hover">Contact</a>
        </nav>
        <nav>
          <h6 class="footer-title">Legal</h6>
          <a class="link link-hover">Terms of use</a>
          <a class="link link-hover">Privacy policy</a>
          <a class="link link-hover">Cookie policy</a>
        </nav>
        <form @submit.prevent="signup">
          <h6 class="footer-title">Newsletter</h6>
          <fieldset class="form-control w-80">
            <label class="label">
              <span class="label-text">Enter your email address</span>
            </label>
            <div class="join">
              <input
                v-model="form.email"
                id="email"
                type="text"
                placeholder="username@site.com"
                class="input input-bordered join-item"
              />
              <button
                :class="[
                  'btn ',
                  'bg-gradient-to-r',
                  'from-violet-500',
                  'to-fuchsia-500',
                  'border-none',
                  'text-white',
                  'join-item',
                  'shadow-lg',
                  'shadow-indigo-500/50',
                  'hover:text-neutral-900'
                ]"
              >
                Subscribe
              </button>
            </div>
          </fieldset>
          <div
            v-if="responseMessage"
            class="mt-1"
            :class="{
              'text-green-500': isSuccess,
              'text-red-500': !isSuccess
            }"
          >
            {{responseMessage}}
          </div>
        </form>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: '',
      },
      responseMessage: '',
      isSuccess: false,
    };
  },
  methods: {
    async signup() {
      this.isSuccess = false;
      this.responseMessage = '';

      try {
        const response = await fetch('/newsletter.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify(this.form)
        });

        const result = await response.json();

        if (result.success) {
          this.form.email = '';
          this.responseMessage = result.message;
          this.isSuccess = true;
        } else {
          this.responseMessage = result.error;
        }
      } catch (err) {
        this.responseMessage = 'An unexpected error occurred.';
      }
    },
  }
};
</script>